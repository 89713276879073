<div class="dis-flex">
    <div class="align-center">
        <div [ngStyle]="{'width.px': imageWidth}" class="mr-2">
            <div class="c-img-s-b-dark">
                <img [src]="imagePath" [alt]="imageAlt" (error)="handleImgError()">
            </div>
        </div>
    </div>
    <div class="align-center">
        <div *ngFor="let text of texts">
            <div *ngIf="text.value" [ngClass]="text.class">{{text.value}}</div>
        </div>
        <ng-container *ngTemplateOutlet="templateTexts"></ng-container>
    </div>
</div>