import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fx-pagination[totalItem][currentPage]',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  
  faChevronLeft = faChevronLeft; faChevronRight = faChevronRight;

  @Input() totalItem: number = 0;
  @Input() itemPerPage: number = 5;
  @Input() currentPage: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  totalPage: number = 0;
  pageNumbers: number[] = [];
  showPageNumbers: number[] = [];

  constructor() { }

  ngOnChanges() {
    this.totalPage = 0;
    this.pageNumbers = [];
    this.showPageNumbers = [];
    this.totalPage = Math.ceil(this.totalItem / this.itemPerPage);
    for(let i = 1; i <= this.totalPage; i++) { this.pageNumbers.push(i); } // setup page number to show
    this.pagination();
  }

  ngOnInit(): void {
  }

  pagination(currentPage: number = 1) {
    if(this.totalPage > 5) {
      if(currentPage < 3) {
        this.showPageNumbers = [...this.pageNumbers.slice(0, currentPage == 1 ? currentPage + 4 : currentPage + 3)];
      } else if (currentPage > this.totalPage - 5) {
        this.showPageNumbers = [...this.pageNumbers.slice(this.totalPage - 5, this.totalPage)];
      } else {
        this.showPageNumbers = [...this.pageNumbers.slice(currentPage - 3, currentPage + 2)];
      }
    } else {
      this.showPageNumbers = this.pageNumbers;
    }
  }

  changePage(selectedPage: number) {
    this.currentPage = selectedPage;
    this.pagination(selectedPage);
    this.pageChange.emit(selectedPage);
  }

  onNextPage() {
    if(this.currentPage < this.totalPage) {
      this.changePage(this.currentPage + 1);
    }
  }

  onPrevPage() {
    if(this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

}
