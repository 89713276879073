import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Brand } from 'src/app/features/brand/interfaces/brand.interface';
import { Category } from 'src/app/features/category/interfaces/category.interface';
import { ProductForm } from '../interfaces/product-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductFormService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  setFormLocalStorage(formValue: ProductForm, category?: Category, brand?: Brand) {
    if(isPlatformBrowser(this.platformId)) 
      localStorage.setItem('productForm', JSON.stringify({
        form: formValue,
        category: category,
        brand: brand,
      }));
  }

  getFormLocalStorage() {
    if(isPlatformBrowser(this.platformId)) 
      return JSON.parse(localStorage.getItem('productForm') as any);
  }

  removeFormLocalStorage() {
    if(isPlatformBrowser(this.platformId)) 
      localStorage.removeItem('productForm');
  }
}
