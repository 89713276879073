import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fxCurrency'
})
export class FxCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: number | string, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
    return super.transform(
      value,
      currencyCode || 'VND',
      display || 'symbol',
      digitsInfo || '1.0-0',
      locale || 'vi-VN',
    );
  }
  // register locale at main.ts files
}