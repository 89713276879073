import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { User } from '../interfaces/user.interface';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private loadUser: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
  ) {
    if(isPlatformBrowser(this.platformId)) this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user') as any));
  }

  public get currentUserValue() {
    if(isPlatformBrowser(this.platformId)) return this.currentUserSubject.value;
    return null;
  }

  getUserData(): Observable<any> | null {
    if(this.loadUser.value != true) this.fetchUserData().subscribe();
    if(isPlatformBrowser(this.platformId)) return this.currentUserSubject.asObservable();
    return null;
  }

  changeCurrentUserData(userData: User | null) {
    if(isPlatformBrowser(this.platformId)) {
      this.currentUserSubject.next(userData);
      if(userData) localStorage.setItem('user', JSON.stringify(userData));
    }
  }

  private fetchUserData() {
    return this.http.get(apiUrl+'user/me').pipe(map((res: any) => {
      let userData = res.data;
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('user', JSON.stringify(userData));
      }
      this.changeCurrentUserData(userData);
      this.loadUser.next(true);
      return userData;
    }));
  }
}
