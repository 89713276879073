<div class="header-wrapper">
    <div class="dis-flex content">
        <div class="flex-item align-center">
            <img src="/assets/images/logo-hb.png" alt="Happy Beauty" class="logo">
        </div>
        <div class="flex-item align-center">
            <div class="text-right" *ngIf="isLoggedIn">
                <fx-header-notification></fx-header-notification>
                <div class="user d-inline-block dropdown">
                    <a href="javascript:void(0)" class="icon dropdown-toggle" type="button" id="userDropdown"
                        data-toggle="dropdown" data-display="static">
                        <fa-icon [icon]="faUser"></fa-icon>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right user" aria-labelledby="userDropdown">
                        <div class="dropdown-item user-menu">
                            <ul class="list-unstyled">
                                <a routerLink="/shop/profile" class="link">
                                    <fa-icon [icon]="faStore"></fa-icon> Shop Profile
                                </a>
                                <a (click)="logout()" class="link">
                                    <fa-icon [icon]="faSignOutAlt"></fa-icon> Logout
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right" *ngIf="!isLoggedIn">
                <a class="btn-p-darkest-l" [href]="marketplaceUrl">Go to Marketplace</a>
            </div>
        </div>
    </div>
</div>