import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './features/auth/services/auth.service';

@Component({
  selector: 'fx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLoggedIn: boolean = false;
  hideSidebar: boolean = true;
  subscription?: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.subscription = this.authService.userTokenEmitter().subscribe((tokens: any) => {
      tokens ? this.isLoggedIn = true : this.isLoggedIn = false;
    });
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(event => {
      this.modifyHeaderFooter(event);
    });
  }

  modifyHeaderFooter(location: any) {
    if(location.url.includes('auth') || location.url.includes('/shop/register')) {
      this.hideSidebar = true;
    } else {
      this.hideSidebar = false;
    }
  }
}
