<div class="pagination" *ngIf="totalPage > 1">
    <a class="prev-btn" (click)="onPrevPage()" [ngClass]="currentPage < 2 ? 'disabled' : ''"><fa-icon [icon]="faChevronLeft"></fa-icon></a>
    <div class="page-number" *ngIf="currentPage > 5">
        <div class="page-box" (click)="changePage(1)">
            <div class="number">1</div>
        </div>
    </div>
    <div class="page-number" *ngIf="currentPage > 5"><div class="page-box dots"> <div class="number">...</div></div></div>
    <div class="page-number" *ngFor="let page of showPageNumbers">
        <div class="page-box" [ngClass]="page == currentPage ? 'active': ''" (click)="changePage(page)">
            <div class="number">{{page}}</div>
        </div>
    </div>
    <div class="page-number" *ngIf="currentPage < totalPage - 5"><div class="page-box dots"> <div class="number">...</div></div></div>
    <div class="page-number" *ngIf="currentPage < totalPage - 5">
        <div class="page-box" (click)="changePage(totalPage)">
            <div class="number">{{totalPage}}</div>
        </div>
    </div>
    <a class="next-btn" (click)="onNextPage()" [ngClass]="currentPage == totalPage ? 'disabled' : ''"><fa-icon [icon]="faChevronRight"></fa-icon></a>
</div>