import { Component, OnDestroy, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Notification } from 'src/app/features/notification/interfaces/notification.interface';
import { NotificationService } from 'src/app/features/notification/services/notification.service';

@Component({
  selector: 'fx-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss']
})
export class HeaderNotificationComponent implements OnInit, OnDestroy {

  faBell = faBell;

  notification: Notification = {} as Notification;
  subscription?: Subscription;

  constructor(
    private notificationService: NotificationService,
  ) { 
  }

  ngOnInit(): void {
    this.subscription = this.notificationService.getNotifications().subscribe((data) => {
      this.notification = data;
    });
  }

  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe();
  }

}
