<div class="header">
    <fx-header [isLoggedIn]="isLoggedIn"></fx-header>
</div>
<div class="dis-flex body">
    <div class="sidebar" *ngIf="!hideSidebar">
        <fx-sidebar></fx-sidebar>
    </div>
    <div class="content">
        <div class="c-container">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<ngx-loading-bar [color]="'#D29C32'" [includeSpinner]="false" [height]="'5px'"></ngx-loading-bar>