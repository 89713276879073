import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Brand } from '../interfaces/brand.interface';

const apiUrl = environment.apiUrl + 'merchant/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  private brandSubject: BehaviorSubject<Brand[] | null> = new BehaviorSubject<Brand[] | null>(null);

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  private fetchMerchantBrand() {
    return this.http.post(apiUrl + '/search', { item_per_page: 1000, current_page: 1 }).pipe(
      map((res: any) => { return res.data.brands; }),
      publishReplay(1),
      refCount()
    );
  }
  
  getMerchantBrand() {
    if(!this.brandSubject.value) {
      this.fetchMerchantBrand().subscribe((data) => {
        this.brandSubject?.next(data);
        return data;
      });
    }
    return this.brandSubject.asObservable();
  }

  addBrand(formValue: any) {
    if(this.brandSubject.value?.find(p => p.name === formValue.name)) return throwError("Brand existed, use search to find brand");
    return this.http.post(apiUrl, formValue).pipe(map((res: any) => {
      this.handleSuccess('Successfully add new brand');
      const data = res.data;
      let brands = this.brandSubject.value;
      let newBrands = {
        id: data.id,
        slug: data.slug,
        name: data.name,
        created_at: data.created_at,
        updated_at: data.updated_at,
        is_active: true
      }
      brands?.push(newBrands)
      this.brandSubject.next(brands);
      return newBrands;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  deleteMerchantBrand(id: number) {
    return this.http.delete(apiUrl+'/'+id).pipe(map((res) => {
      this.handleSuccess('Successfully delete merchant brand');
      return res;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  private handleSuccess(message: string) {
    this.toastr.success(message);
  }

  private handleError(error: any) {
    var errorMessage = 'Something went wrong, please try again';
    if (error?.message) {
      errorMessage = error.message;
    }
    return throwError(errorMessage);
  }


  filterBrands(brands: Brand[], keywords: string) {
    return brands.filter((x) => x.name.toLowerCase().includes(keywords.toLowerCase()));
  }

  clearBrandObservable() {
    this.brandSubject.next(null);
  }
}
