import { Component, Input, OnInit } from '@angular/core';
import { faSignOutAlt, faStore, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faUser = faUser; faStore = faStore; faSignOutAlt = faSignOutAlt;

  @Input() isLoggedIn: boolean = false;
  defaultPropic = 'assets/images/default-user.png';
  marketplaceUrl: any = environment.marketplaceUrl;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout().subscribe();
  }
}
