import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, forkJoin } from 'rxjs';
import { Notification, NotificationContent } from '../interfaces/notification.interface';
import { DateHelperService } from 'src/app/shared/helpers/date-helper.service';

const apiUrl = environment.apiUrl + 'merchant/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notification: BehaviorSubject<Notification> = new BehaviorSubject<Notification>({ totalUnread: 0, totalNotifications: 0, notifications: [] });

  constructor(
    private http: HttpClient,
    private dateHelper: DateHelperService,
  ) { }

  getNotifications() {
    this.fetchNotification();
    return this.notification.asObservable();
  }

  markAsRead(id: number) {
    return this.http.post(apiUrl+'/mark_as_read', { id: id }).pipe(map((res) => {
      this.getNotifications().subscribe();
      return res;
    }));
  }

  markAsUnread(id: number) {
    return this.http.post(apiUrl+'/mark_as_unread', { id: id }).pipe(map((res) => {
      this.getNotifications().subscribe();
      return res;
    }));
  }

  markAllAsRead() {
    return this.http.post(apiUrl+'/mark_all_as_read', '').pipe(map((res) => {
      this.getNotifications().subscribe();
      return res;
    }));
  }

  private fetchNotification() {
    forkJoin([
      this.fetchUnreadNotification(),
      this.getAllNotification(),
    ]).subscribe(([unreadData, notificationData]) => {
      this.notification.next({ 
        totalUnread: unreadData.count, 
        totalNotifications: notificationData.totalItems,
        notifications: notificationData.notifications 
      });
    });
  }

  getAllNotification(itemPerPage: number = 5, currentPage: number = 1) {
    return this.http.post(apiUrl+'/all', {
      item_per_page: itemPerPage,
      current_page: currentPage,
      sorts: [{ field: 'notifications.created_at', direction: 'desc' }]
    }).pipe(map((res: any) => {
      let notifications: NotificationContent[] = res.data.notifications;
      notifications.forEach(notification => {
        if(notification.created_at) {
          let date = new Date(notification.created_at);
          notification.altered_datetime = new Date(date.setHours(date.getHours()));
          notification.timeStamp = this.dateHelper.idTimeStampFormatter(date);
        }
      });
      return { totalItems: res.data.total_items,  notifications: notifications };
    })).pipe(catchError((error) => {
      return throwError(error);
    }));
  }

  private fetchUnreadNotification() {
    return this.http.post(apiUrl+'/unread', {
      item_per_page: 1000,
      current_page: 1,
      sorts: [{ field: 'notifications.created_at', direction: 'desc' }]
    }).pipe(map((res: any) => {
      return res.data;
    })).pipe(catchError((error) => {
      return throwError(error);
    }));
  }

}
