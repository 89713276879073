import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationComponent } from './pages/notification/notification.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaginationModule } from 'src/app/utilities/pagination/pagination.module';


@NgModule({
  declarations: [
    NotificationComponent, 
    NotificationListComponent
  ],
  imports: [
    NotificationRoutingModule,
    PaginationModule,
    SharedModule,
  ],
  exports: [NotificationListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NotificationModule { }
