import { Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../interfaces/user.interface';
import { ToastrService } from 'ngx-toastr';
import { ShopService } from '../../shop/services/shop.service';
import { CacheService } from 'src/app/shared/helpers/cache.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserTokensSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private facebookUserInfoSubject: BehaviorSubject<any>  = new BehaviorSubject(null);
  fcmToken = "";

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private shopService: ShopService,
    private cacheService: CacheService,
  ) {
    if(isPlatformBrowser(this.platformId)) {
      this.currentUserTokensSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('tokens') as any));
      this.facebookUserInfoSubject = new BehaviorSubject(null);
    }
  }

  userTokenEmitter(): Observable<any> | any {
    if(isPlatformBrowser(this.platformId)) {
      return this.currentUserTokensSubject.asObservable();
    }
  }

  public get getCurrentUserTokens() {
    if(isPlatformBrowser(this.platformId))  return this.currentUserTokensSubject.value;
  }

  public get facebookUserInfoValue() {
    if(isPlatformBrowser(this.platformId)) return this.facebookUserInfoSubject.value;
  } 

  login(loginData: any) {
    loginData.registration_token = this.fcmToken; //present fcm token
    return this.http.post(apiUrl+'guest/login', loginData).pipe(map((res: any) => {
      const resData = res.data;
      this.handleAuthentication(resData.tokens).subscribe();
    })).pipe(catchError((error) => {
      return this.handleError(error);
    }));
  }

  signInWithGoogle(googleId: any, googleToken: any) {
    const body = {
      google_id: googleId,
      google_token: googleToken,
      registration_token: this.fcmToken
    }
    return this.http.post(apiUrl+'guest/login-google', body).pipe(map((res: any) => {
      const resData = res.data;
      this.handleAuthentication(resData.tokens).subscribe();
    })).pipe(catchError((error) => {
      return this.handleError(error);
    }));
  }

  signInWithFacebook(facebookId: any, facebookToken: any, email: string) {
    const body = {
      facebook_id: facebookId,
      facebook_token: facebookToken,
      email: email,
      phone_number: null,
      registration_token: this.fcmToken
    }
    return this.http.post(apiUrl+'guest/login-facebook', body).pipe(map((res: any) => {
      const resData = res.data;
      this.handleAuthentication(resData.tokens).subscribe();
    })).pipe(catchError((error) => {
      return this.handleError(error);
    }));
  }

  changeFacebookUserInfo(facebookUserInfo: any) {
    this.facebookUserInfoSubject.next(facebookUserInfo);
  }

  logout() {
    let body;
    if (this.fcmToken) { body = { registration_token: this.fcmToken }; }
    return this.http.post(apiUrl + 'user/logout', body).pipe(map(() => {
      this.handleLogout();
    })).pipe(catchError((error) => {
      this.handleLogout();
      return throwError(error);
    }));
  }

  private handleLogout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('user');
      localStorage.removeItem('shop');
    }
    this.socialAuthService.signOut();
    this.userService.changeCurrentUserData(null);
    this.currentUserTokensSubject.next(null);
    this.facebookUserInfoSubject.next(null);
    this.shopService.currentUserShopSubject.next(null);
    this.cacheService.clearAllCache();
    this.router.navigate(['/auth/login']);
  }

  private handleAuthentication(tokens: any) {
    //store user tokens to local storage
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('tokens', JSON.stringify(tokens));
      this.currentUserTokensSubject.next(tokens);
    }
    //store user data to local storage
    return this.http.get(apiUrl+'user/me').pipe(map((res: any) => {
      const userData: User = res.data;
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('user', JSON.stringify(userData));
        this.userService.changeCurrentUserData(userData);
        this.handleSuccess(`Welcome ${userData.name}`);
      }
      return userData;
    }));
  }

  private handleSuccess(message: string) {
    this.toastr.success(message);
    this.shopService.getMyShop().subscribe((shop) => {
      shop ? this.router.navigate(['/']) : this.router.navigate(['/shop/register']);
    });
  }

  private handleError(errorRes: any) {
    let errorMessage = 'Terjadi kesalahan yang tidak diketahui';
    let errorType = 0; // if 1 == wrong email or password
    if(errorRes['data'].email && errorRes['data'].email.length > 0) {
      errorMessage = errorRes['data'].email[0]
    } else if(errorRes['data'].password && errorRes['data'].password.length > 0) {
      errorMessage = errorRes['data'].password[0]
    } else if(errorRes['data'] && errorRes['data'].errorCode && errorRes['data'].errorCode == 'BBAuth001') {
      errorType = 1;
      errorMessage = 'Wrong email or password.';
    } else if(errorRes['message'] || errorRes['message'].length > 0) {
      errorMessage = errorRes['message'];
    }
    return throwError({ errorType: errorType, errorMessage: errorMessage });
  }
  
}
