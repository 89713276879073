import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fxAvgRating'
})
export class FxAvgRatingPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: unknown): unknown {
    var val = Number(value)
    if(val % 1 != 0) {
      return this.decimalPipe.transform(val, '1.1-1');
    } else {
      return val ? val : 0;
    }
  }

}
