<div class="sidebar-wrapper">
    <ul class="sidebar-menu">
        <div class="sidebar-menu-box" *ngFor="let menu of menus">
            <div class="sidebar-menu-item" (click)="menu.toggle = !menu.toggle">
                <div class="dis-flex">
                    <fa-icon [icon]="menu.icon" class="icon"></fa-icon>
                    <span class="sidebar-menu-text">{{menu.name}}</span>
                </div>
                <fa-icon [icon]="menu.toggle ? faChevronUp : faChevronDown" class="chevron"></fa-icon>
            </div>
            <ul class="sidebar-submenu" *ngIf="menu.toggle">
                <li class="sidebar-submenu-item" *ngFor="let submenu of menu.submenus">
                    <a class="sidebar-menu-item-link" [routerLink]="submenu.link" routerLinkActive="active">{{submenu.name}}</a>
                </li>
            </ul>
        </div>
    </ul>
</div>