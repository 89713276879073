import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Category } from '../interfaces/category.interface';
import { Observable } from 'rxjs';

const apiUrl = environment.apiUrl + 'guest/';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private categoryObservable?: Observable<any>;

  constructor(private http: HttpClient) { }

  clearCategoryObservable() {
    this.categoryObservable = undefined;
  }

  getCategories() {
    if(!this.categoryObservable) {
      this.categoryObservable = this.http.get(apiUrl+'categories').pipe(
        map((res:any) => {
          const categories: Category[] = res.data;
          return categories;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.categoryObservable;
  }

  filterCategory(pCategories: Category[], keywords: string) {
    const categories = this.disjoint(pCategories);
    const filtered = categories.filter((x) => x.name.toLowerCase().includes(keywords.toLowerCase()));
    return filtered;
  }

  private disjoints: Category[] = [];
  private disjoint(categories: Category[]) {
    this.disjoints = [];
    categories.forEach(cat => {
      this.disjoints.push(cat);
      if(cat.children.length > 0) {
        cat.children.forEach(child => this.disjointChildren(child));
      }
    });
    return this.disjoints;
  }
  private disjointChildren(children: Category) {
    if(children) {
      this.disjoints.push(children);
      if(children.children.length > 0) {
        children.children.forEach(child => this.disjointChildren(child));
      }
    }
  }

  private categorySet: Category[] = [];
  findCategorySet(pCategories: Category[], slug: string) {
    const categories = this.disjoint(pCategories);
    this.categorySet = [];
    let found = categories.find(cat => cat.slug === slug);
    if(found) {
      this.categorySet.push(found);
      if(found.parent_id != null) {
        this.findParentSlug(categories, found.parent_id);
      }
    }
    return this.categorySet.reverse();
  }
  private findParentSlug(categories: Category[], parentId: number) {
    let found = categories.find(cat => cat.id === parentId);
    if(found) {
      this.categorySet.push(found);
      if(found.parent_id != null) {
        this.findParentSlug(categories, found.parent_id);
      }
    }
  }
  
}
