<div class="notification-wrapper content-card">
    <div class="header">
        <div class="dis-flex">
            <div class="flex-item align-center">
                <div class="title">All Notification</div>
            </div>
            <div class="flex-item align-center">
                <div class="c-btn-group-rtl">
                    <button class="btn-p-darkest-o" (click)="markAllAsRead()">Mark all as read</button>
                </div>
            </div>
        </div>
    </div>
    <div class="content p-0">
        <fx-notification-list [notifications]="notifications"></fx-notification-list>
    </div>
    <div class="footer">
        <fx-pagination [totalItem]="totalItems" [itemPerPage]="5" [currentPage]="currentPage" (pageChange)="pageChange($event)"></fx-pagination>
    </div>
</div>