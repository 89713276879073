// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.happybeauty.com.vn/api/v1/',
  marketplaceUrl: 'https://vn.happybeauty.sg',
  'google-oauth2-client-id': '469360172325-b5r46fkl33l9lhgls7sh7oudbi1ih6qj.apps.googleusercontent.com',
  'facebook-app-id': '168941821726606',
  'stripe-publishable-key': 'pk_live_Z0yONZgPPIbJROpAEj7TJssw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
