import { Component, OnInit } from '@angular/core';
import { faBandcamp } from '@fortawesome/free-brands-svg-icons';
import { faArchive, faChevronDown, faChevronUp, faClipboardList, faCoins, faStore, faTag, faTicketAlt, faTruck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fx-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  faChevronUp = faChevronUp; faChevronDown = faChevronDown; 
  faStore = faStore; faTruck = faTruck; faCoins = faCoins; faClipboardList = faClipboardList; faArchive = faArchive; faTag = faTag; faTicketAlt = faTicketAlt; faBandcamp = faBandcamp;

  menus = [
    {
      icon: faStore,
      name: "Shop",
      toggle: true,
      submenus: [
        { name: "Shop Profile", link: "/shop/profile" },
      ]
    },
    // {
    //   icon: faTruck,
    //   name: "Shipment",
    //   toggle: true,
    //   submenus: [
    //     { name: "Shipping Method", link: "/shipment/shipping-method" },
    //   ]
    // },
    {
      icon: faCoins,
      name: "Happy Pay",
      toggle: true,
      submenus: [
        { name: "Transaction", link: "/happy-pay/activity" },
        { name: "Top Up", link: "/happy-pay/topup" },
        { name: "Withdrawal", link: "/happy-pay/withdrawal" },
        { name: "Bank Account", link: "/happy-pay/bank" },
      ]
    },
    {
      icon: faClipboardList,
      name: "Order",
      toggle: true,
      submenus: [
        { name: "Order", link: "/order/p" },
        { name: "Cancelled Order", link: "/order/c/cancelled" },
        { name: "Reported Order", link: "/order/r/reported" },
      ]
    },
    {
      icon: faArchive,
      name: "Product",
      toggle: true,
      submenus: [
        { name: "Products", link: "/product" },
      ]
    },
    {
      icon: faTag,
      name: "Promotion",
      toggle: true,
      submenus: [
        { name: "Promotion Event", link: "/promotion" },
      ]
    },
    {
      icon: faTicketAlt,
      name: "Voucher",
      toggle: true,
      submenus: [
        { name: "Shop Voucher", link: "/voucher" },
      ]
    },
    // {
    //   icon: faBandcamp,
    //   name: "Brand",
    //   toggle: true,
    //   submenus: [
    //     { name: "Merchant Brands", link: "/brand" },
    //   ]
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
