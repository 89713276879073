import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInteceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { HeaderNotificationComponent } from './components/header/header-notification/header-notification.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NotificationModule } from '../features/notification/notification.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNotificationComponent,
    SidebarComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    LoadingBarHttpClientModule,
    NotificationModule,
    NgbModule,
    NgbModalModule,
    SharedModule,
  ], 
  exports: [
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    LoadingBarHttpClientModule,
    NgbModule,
    NgbModalModule,
    //
    HeaderComponent,
    SidebarComponent,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInteceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment['google-oauth2-client-id']
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment['facebook-app-id']),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ]
})
export class CoreModule { }
