import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'fx-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit {

  @Input() imagePath?: string;
  @Input() imageAlt?: string;
  @Input() imageWidth: number = 45;
  @Input() texts?: [{ value: string, class: string }];

  @Input() templateTexts?: TemplateRef<any>;

  constructor() { 
    if(!this.imagePath) this.imagePath = 'assets/images/product/default-product.png';
  }

  ngOnInit(): void {
  }

  handleImgError() { this.imagePath = "assets/images/product/default-product.png"; }

}
