import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Shop } from '../interfaces/shop.interface';

const apiUrl = environment.apiUrl+'user/shop';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  currentUserShopSubject: BehaviorSubject<Shop | null> = new BehaviorSubject<Shop | null>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
  ) { 
    if(isPlatformBrowser(this.platformId)) {
      this.currentUserShopSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('shop') as any));
    }
  }

  public get getCurrentUserShop() {
    if(isPlatformBrowser(this.platformId))  return this.currentUserShopSubject.value;
    return null;
  }

  getShopData(): Observable<any> | null {
    if(!this.currentUserShopSubject.value) this.getMyShop().subscribe();
    if(isPlatformBrowser(this.platformId)) return this.currentUserShopSubject.asObservable();
    return null;
  }

  changeCurrentUserShopData(shopData: Shop) {
    if(isPlatformBrowser(this.platformId)) {
      this.currentUserShopSubject.next(shopData);
      localStorage.setItem('shop', JSON.stringify(shopData));
    }
  }

  getMyShop(): Observable<Shop | null> {
    return this.http.post(apiUrl+'/search', { item_per_page: 1, current_page: 1 }).pipe(map((res: any) => {
      const totalItem: number = res.data.total_items;
      const shops: Shop[] = res.data.shops;
      if(totalItem < 1) {
        this.router.navigate(['/shop/register']);
        return null;
      } else {
        const shop = shops[0]
        if(isPlatformBrowser(this.platformId)) localStorage.setItem('shop', JSON.stringify(shop));
        this.currentUserShopSubject.next(shop);
        return shop;
      }
    }));
  }

  createShop(data: any) {
    return this.http.post(apiUrl, data).pipe(map((res: any) => {
      this.handleSuccess(res.message, res.data);
      return res;
    }));
  }

  updateShopData(id: number, data: any) {
    return this.http.put(apiUrl+'/'+id, data).pipe(map((res: any) => {
      this.handleSuccess(res.message, res.data);
      return res;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  uploadShopLogo(id: number, formData: FormData) {
    return this.http.post(`${apiUrl}/${id}/upload_logo`, formData).pipe(map((res: any) => {
      this.handleSuccess(res.message, res.data);
      return res.data.logo_path;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  uploadShopCover(id: number, formData: FormData) {
    return this.http.post(`${apiUrl}/${id}/upload_cover`, formData).pipe(map((res: any) => {
      this.handleSuccess(res.message, res.data);
      return res.data.cover_path;
    })).pipe(catchError((error) => this.handleError(error)));
  }

  private handleSuccess(message: string, shop?: Shop) {
    if(shop) {
      if(isPlatformBrowser(this.platformId)) localStorage.setItem('shop', JSON.stringify(shop));
    }
    this.toastr.success(message);
  }
  
  private handleError(error: any) {
    var errorMessage = 'Something went wrong, please try again';
    if (error?.data?.image?.length > 0) {
      errorMessage = error?.data?.image[0];
    } else if (error?.data?.cover?.length > 0) {
      errorMessage = error?.data?.cover[0];
    } else {
      errorMessage = error?.message;
    }
    return throwError(errorMessage);
  }

}
