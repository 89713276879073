import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  idTimeStampFormatter(pDate: Date) {
    const date = new Date(pDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    let indonesiaMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

    let timeStamp = `${day} ${indonesiaMonth[month]} ${year} ${('0'+hour).slice(-2)}:${('0'+minute).slice(-2)}`;
    return (timeStamp);
  }
}
