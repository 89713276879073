<div class="notification d-inline-block position-relative dropdown">
    <a href="javascript:void(0)" class="icon dropdown-toggle" type="button" id="notifDropdown" data-toggle="dropdown" data-display="static">
        <fa-icon [icon]="faBell"></fa-icon>
        <span class="badge badge-counter" *ngIf="notification.totalUnread > 0">{{notification.totalUnread}}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notifDropdown">
        <h6 class="dropdown-header">Notifications 
            <span class="badge badge-pill unread-badge" *ngIf="notification.totalUnread > 0">{{notification.totalUnread}}Unread</span>
        </h6>
        <div class="dropdown-content">
            <fx-notification-list [notifications]="notification.notifications"></fx-notification-list>
        </div>
        <div class="dropdown-item text-center" *ngIf="notification.notifications.length > 0">
            <button class="btn-p-darkest-l" routerLink="/notification">View All</button>
        </div>
    </div>
</div>