import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationContent } from '../../interfaces/notification.interface';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'fx-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  @Input() notifications: NotificationContent[] = [];

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  ngOnInit(): void { }

  redirect(notification: NotificationContent) {
    const type = notification.data.type
    if (type >= 0 && type <= 7) {
      this.router.navigate(['/user/order/view', notification.data.order_id]);
    } else if (type === 8) {
      this.router.navigate(['/product/view/', notification.data.product_id, { tab: 'discussions' }]);
    } else if (type >= 11 && type <= 13 ) {
      this.router.navigate(['/product/view/', notification.data.product_id]);
    } else if (type === 9) {
      this.router.navigate(['/beauty-book/view/', notification.data.commentable_id]);
    } else if (type === 10) {
      this.router.navigate(['/beauty-book/view/', notification.data.likeable_id]);
    } else if (type === 15 || type === 16 || type === 24) {
      this.router.navigate(['/user/checkout/view', notification.data.checkout_id]);
    } else if (type === 21 || type === 22) {
      this.router.navigate(['/user/happy-pay/withdrawal']);
    } else if (type === 18 || type === 19) {
      this.router.navigate(['/user/happy-pay/topup']);
    } else if (type === 23) {
      this.router.navigate(['/user/happy-pay']);
    }
    this.notificationService.markAsRead(notification.id).subscribe(() => {
      this.notificationService.getNotifications();
    });
  }
}
