import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../features/auth/services/auth.service';
import { ShopService } from 'src/app/features/shop/services/shop.service';

@Injectable()
export class JwtInteceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private shopService: ShopService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUserTokens = this.authService.getCurrentUserTokens;
        let shopId = this.shopService.getCurrentUserShop?.id;
        var headers: any = {}
        if(currentUserTokens && currentUserTokens.access_token) headers.Authorization = `Bearer ${currentUserTokens.access_token}`
        if(shopId) headers.shopId = String(shopId)
        request = request.clone({
            setHeaders: headers
        });
        return next.handle(request);
    }
}