import { Component, OnInit } from '@angular/core';
import { NotificationContent } from '../../interfaces/notification.interface';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'fx-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  totalItems: number = 0;
  notifications: NotificationContent[] = [];
  currentPage = 1;
  
  constructor(
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.loadNotifications();
  }

  loadNotifications() {
    this.notificationService.getAllNotification(5, this.currentPage).subscribe((data) => {
      this.totalItems = data.totalItems;
      this.notifications = data.notifications;
    });
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.loadNotifications();
  }

  markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.notifications.forEach((x) => x.read_at = new Date());
    });
  }

}
