import { Injectable } from '@angular/core';
import { ProductFormService } from 'src/app/features/product/services/product-form.service';
import { BrandService } from '../../features/brand/services/brand.service';
import { CategoryService } from '../../features/category/services/category.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private brandService: BrandService,
    private categoryService: CategoryService,
    private productFormService: ProductFormService,
  ) { }

  clearAllCache() {
    this.brandService.clearBrandObservable();
    this.categoryService.clearCategoryObservable();
    this.productFormService.removeFormLocalStorage();
  }
}
