import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { SocialAuthService } from 'angularx-social-login';
import { catchError } from 'rxjs/operators';
import { UserService } from 'src/app/features/auth/services/user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private userService: UserService,
    // private router: Router,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((e) => {
      if(e.status == 401) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem('tokens');
          localStorage.removeItem('user');
          localStorage.removeItem('shop');
        }
        this.socialAuthService.signOut();
        this.userService.currentUserSubject.next(null);
        this.authService.currentUserTokensSubject.next(null);
      } 
      // else if(e.status == 404) {
      //   this.router.navigate(['/error/not-found']);
      // }
      const error = e.error;
      return throwError(error);
    }));
  }
}
